define("discourse/plugins/discourse-characters-required/discourse/components/characters-required", ["exports", "@ember/component", "@ember-decorators/component", "discourse-common/utils/decorators"], function (_exports, _component, _component2, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CharactersRequired = dt7948.c(class CharactersRequired extends _component.default {
    showRequired(missing) {
      return missing > 0;
    }
    static #_ = (() => dt7948.n(this.prototype, "showRequired", [(0, _decorators.default)("composer.missingReplyCharacters")]))();
  }, [(0, _component2.classNames)("characters-required")]);
  var _default = _exports.default = CharactersRequired;
});