define("discourse/plugins/discourse-fingerprint/discourse/routes/admin-plugins-fingerprint", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsFingerprintRoute extends _route.default {
    controllerName = "fingerprintReport";
    templateName = "fingerprintReport";
    setupController(controller) {
      controller.update(controller.username);
    }
  }
  _exports.default = AdminPluginsFingerprintRoute;
});