define("discourse/plugins/discourse-tooltips/mixins/topic-hover-extension", ["exports", "@ember/runloop", "jquery", "discourse/lib/ajax", "discourse-common/lib/object", "discourse/plugins/discourse-tooltips/discourse/lib/event-from"], function (_exports, _runloop, _jquery, _ajax, _object, _eventFrom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hoverExtension = hoverExtension;
  // How many extra post excerpts to retrieve
  const READ_AHEAD = 4;
  let _cached = {};
  let _promise;
  let _inside = false;
  function cleanDom() {
    (0, _jquery.default)(".d-tooltip").remove();
    _inside = false;
  }
  function cancel() {
    if (_promise) {
      _promise.abort();
      _promise = null;
    }
    cleanDom();
  }
  function renderTooltip($this, text) {
    if (!text) {
      return;
    }
    $this.after(`<div class='d-tooltip'><div class='d-tooltip-pointer'></div><div class='d-tooltip-content'>${text}</div></div></div>`);
    let $dTooltip = (0, _jquery.default)(".d-tooltip");
    let tooltipWidth = $dTooltip.outerWidth();
    let elementWidth = $this.width();
    let elementHeight = $this.height();
    let elementPos = $this.position();
    let elementX = elementPos.left;
    let y = elementPos.top + elementHeight;
    let x = elementX + elementWidth / 2 - tooltipWidth / 2;

    // make sure left side of the tooltip is not out of the screen
    let $mainLink = $this.hasClass("main-link") ? $this : $this.parents(".main-link");
    let mainLinkLeftOffset = $mainLink.offset().left;
    if (mainLinkLeftOffset + x < 0) {
      x = elementX;
    }
    $dTooltip.css({
      left: `${x}px`,
      top: `${y}px`
    });
    $dTooltip.fadeIn(200);
  }
  let tooltipsRateLimited = false;
  function hoverExtension(selector) {
    return {
      pluginId: "discourse-tooltips",
      didInsertElement() {
        this._super(...arguments);
        cancel();
        (0, _jquery.default)(this.element).on("mouseenter.discourse-tooltips", selector, function (e) {
          if ((0, _eventFrom.eventFrom)(e) !== "mouse") {
            return;
          }
          let $this = (0, _jquery.default)(this);
          let $parentTopicId = (0, _jquery.default)(e.currentTarget);
          if (typeof $parentTopicId.attr("data-topic-id") === "undefined") {
            $parentTopicId = $parentTopicId.parents("[data-topic-id]").last();
          }
          let topicId = parseInt($parentTopicId.attr("data-topic-id"), 10);
          if (topicId) {
            cancel();
            _inside = true;
            if (_cached[topicId]) {
              return renderTooltip($this, _cached[topicId].excerpt);
            }
            if (tooltipsRateLimited) {
              return;
            }
            let topicIds = [topicId];

            // Let's actually fetch the next few topic ids too, assuming the user will
            // mouse over more below
            let $siblings = $parentTopicId.nextAll(`[data-topic-id]:lt(${READ_AHEAD})`);
            $siblings.each((idx, s) => {
              let siblingId = parseInt(s.getAttribute("data-topic-id"), 10);
              if (!_cached[siblingId]) {
                topicIds.push(siblingId);
              }
            });
            _promise = (0, _ajax.ajax)("/tooltip-previews", {
              data: {
                topic_ids: topicIds
              },
              cache: true
            });
            _promise.then(r => {
              if (r && r.excerpts) {
                (0, _object.deepMerge)(_cached, r.excerpts);
              }
              if (_inside) {
                renderTooltip($this, _cached[topicId].excerpt);
              }
            }).catch(event => {
              const xhr = event.jqXHR;
              if (xhr && xhr.status === 429) {
                tooltipsRateLimited = true;
                let tryAfter = parseInt(xhr.getResponseHeader && xhr.getResponseHeader("Retry-After"), 10) || 0;
                tryAfter = tryAfter || 0;
                if (tryAfter < 15) {
                  tryAfter = 15;
                }
                this.rateLimiter = (0, _runloop.later)(this, () => {
                  tooltipsRateLimited = false;
                }, tryAfter * 1000);
              }

              // swallow errors - was probably aborted!
            });
          }
        });
        (0, _jquery.default)(this.element).on("mouseleave.discourse-tooltips", selector, e => {
          if ((0, _eventFrom.eventFrom)(e) !== "mouse") {
            return;
          }
          (0, _runloop.run)(() => cleanDom());
        });
      },
      willDestroyElement() {
        (0, _runloop.cancel)(this.rateLimiter);
        tooltipsRateLimited = false;
        this._super(...arguments);
        cancel();
        (0, _jquery.default)(this.element).find(selector).off("mouseenter.discourse-tooltips, mouseleave.discourse-tooltips", selector);
      }
    };
  }
});