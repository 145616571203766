define("discourse/plugins/discourse-staff-alias/discourse/templates/connectors/composer-action-after/reply-as-staff-alias-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.isReplyAsStaffAlias}}
    <span class="reply-as-staff-alias-icon">{{d-icon "user-secret"}}</span>
  {{/if}}
  */
  {
    "id": "HvSXboji",
    "block": "[[[41,[30,0,[\"model\",\"isReplyAsStaffAlias\"]],[[[1,\"  \"],[10,1],[14,0,\"reply-as-staff-alias-icon\"],[12],[1,[28,[35,2],[\"user-secret\"],null]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"span\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-staff-alias/discourse/templates/connectors/composer-action-after/reply-as-staff-alias-icon.hbs",
    "isStrictMode": false
  });
});