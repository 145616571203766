define("discourse/plugins/discourse-restricted-replies/discourse/components/restrict-replies-setting", ["exports", "@ember/component", "discourse-common/lib/helpers", "discourse-common/utils/decorators"], function (_exports, _component, _helpers, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RestrictRepliesSetting extends _component.default {
    groups() {
      return (0, _helpers.makeArray)(this.site.groups);
    }
    static #_ = (() => dt7948.n(this.prototype, "groups", [_decorators.default]))();
  }
  _exports.default = RestrictRepliesSetting;
});