define("discourse/plugins/discourse-characters-required/discourse/templates/connectors/after-d-editor/show-required", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CharactersRequired @composer={{this.composer}} />
  */
  {
    "id": "WcJ0N8DE",
    "block": "[[[8,[39,0],null,[[\"@composer\"],[[30,0,[\"composer\"]]]],null]],[],false,[\"characters-required\"]]",
    "moduleName": "discourse/plugins/discourse-characters-required/discourse/templates/connectors/after-d-editor/show-required.hbs",
    "isStrictMode": false
  });
});