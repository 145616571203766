define("discourse/plugins/discourse-geo-customization/discourse/initializers/add-location-to-body", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      (0, _pluginApi.withPluginApi)("0.11.4", api => {
        const user = api.getCurrentUser();
        const body = document.querySelector("body");
        if (user) {
          if (siteSettings.geo_include_country_code) {
            body.classList.add(`country-${user.geo_location.country_code}`);
          }
          if (siteSettings.geo_include_city) {
            body.classList.add(`city-${user.geo_location.city}`);
          }
        }
      });
    }
  };
});