define("discourse/plugins/discourse-characters-required/discourse/templates/components/characters-required", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showRequired}}
    {{i18n "characters_required" count=this.composer.missingReplyCharacters}}
  {{/if}}
  */
  {
    "id": "TqALcFqH",
    "block": "[[[41,[30,0,[\"showRequired\"]],[[[1,\"  \"],[1,[28,[35,1],[\"characters_required\"],[[\"count\"],[[30,0,[\"composer\",\"missingReplyCharacters\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-characters-required/discourse/templates/components/characters-required.hbs",
    "isStrictMode": false
  });
});