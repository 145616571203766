define("discourse/plugins/discourse-geo-customization/discourse/connectors/above-main-container/add-location", ["exports", "discourse/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    currentUser: _user.default.current()
  };
});