define("discourse/plugins/discourse-templates/discourse/connectors/topic-above-posts/discourse-templates-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse-common/lib/debounce"], function (_exports, _component, _tracking, _object, _service, _ajax, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseTemplatesButton extends _component.default {
    static shouldRender(outletArgs, helper) {
      return outletArgs.model.is_template && helper.currentUser?.can_create_topic;
    }
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "copyConfirm", [_tracking.tracked], function () {
      return false;
    }))();
    #copyConfirm = (() => (dt7948.i(this, "copyConfirm"), void 0))();
    async fetchRaw() {
      const topic = this.args.outletArgs.model;
      return await (0, _ajax.ajax)(`/raw/${topic.id}/1`, {
        dataType: "text"
      });
    }
    async createNewTopic() {
      const text = await this.fetchRaw();
      this.composer.openNewTopic({
        body: text
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "createNewTopic", [_object.action]))();
    async copy() {
      const text = await this.fetchRaw();
      navigator.clipboard.writeText(text);
      this.copyConfirm = true;
      (0, _debounce.default)(this.resetCopyButton, 2000);
    }
    static #_4 = (() => dt7948.n(this.prototype, "copy", [_object.action]))();
    resetCopyButton() {
      this.copyConfirm = false;
    }
    static #_5 = (() => dt7948.n(this.prototype, "resetCopyButton", [_object.action]))();
  }
  _exports.default = DiscourseTemplatesButton;
});